import React from "react"
import RichText from "./richText"

import styles from "./sectionHeader.module.scss"

const SectionHeader = (props) => {
  return (
    <div className={styles.sectionHeader} data-width={props.width} data-align={props.align ? props.align : `center`}>
      {props.title && <h2 className={styles.sectionTitle}>{props.title}</h2>}
      {props.subtitle && <h3 className={styles.sectionSubtitle}>{props.subtitle}</h3>}
      {props.content && <RichText blocks={props.content} />}
    </div>
  )
}

export default SectionHeader
