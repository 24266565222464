import React, { Component } from "react"
import RichText from "./richText"
import Icon from "../../assets/svg/info.svg"

import styles from "./toggleTip.module.scss"

class ToggleTip extends Component {
  constructor(props) {
    super(props)
    this.root = {}
  }

  state = {
    isActive: false
  }

  componentDidMount() {
    document.addEventListener("keydown", this.handleEsc)
    this.root = document.documentElement
  }

  componentDidUpdate() {
    this.state.isActive ? (
      document.addEventListener("click", this.handleOutsideClick)
    ) : (
      document.removeEventListener("click", this.handleOutsideClick)
    )
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.handleEsc)
    document.removeEventListener("click", this.handleOutsideClick)
  }

  positionToggleTip (xPos) {
    // reset previous positions
    this.target.setAttribute("data-position", "none")
    this.root.style.setProperty('--tooltipOffset', "0px")

    if (window.innerWidth < 641) {
      const offset = 18
      let pos = (window.innerWidth / 2) - (xPos + offset)

      return this.root.style.setProperty('--tooltipOffset', pos + "px")
    }

    if ((xPos - 230) < 0) this.target.setAttribute("data-position", "left")

    if ((xPos + 230) > window.innerWidth) this.target.setAttribute("data-position", "right")
  }

  handleTriggerClick = (evt) => {
    const xPos = evt.currentTarget.getBoundingClientRect().x

    this.positionToggleTip(xPos)

    this.setState(state => {
      return { isActive: !state.isActive }
    })
  }

  handleOutsideClick = (evt) => {
    if (this.state.isActive && this.target !== evt.target) {
      this.setState(state => {
        return { isActive: false }
      })
    }
  }

  handleEsc = (evt) => {
    if (this.state.isActive && evt.keyCode === 27) {
      this.setState(state => {
        return { isActive: !state.isActive }
      })
    }
  }

  render() {
    return (
      <div className={styles.toggleTip}>
        <button className={styles.toggleTipTrigger} type="button" aria-label="More info" onClick={this.handleTriggerClick}>
          <Icon />
        </button>
        <span className={styles.toggleTipContent} role="status" data-active={this.state.isActive} ref={(node) => {this.target = node}}>
          {this.state.isActive &&
            <span className={styles.toggleTipContentInner}>
              <h4>{this.props.title}</h4>
              <ul>
                {this.props.items.map(item =>
                  <li key={item._key}>
                    <h5>{item.title}</h5>
                    {item.description && <RichText blocks={item.description} />}
                  </li>
                )}
              </ul>
            </span>
          }
        </span>
      </div>
    )
  }
}

export default ToggleTip
