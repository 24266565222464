import React from "react"
import ToggleTip from "./toggleTip"
import Link from "./link"

import styles from "./pricePlans.module.scss"

const PricePlans = (props) => {
  return (
    <section className="grid">
      <div className="gridItem xxlarge-10 xxlarge-offset-1">
        <ul className={styles.plans}>
          {props.plans.map(plan =>
            <li className={styles.plan} key={plan._id}>
              <header className={styles.planHeader}>
                <h4>{plan.title}</h4>
                <div className={styles.planCost}>
                  {plan.label && <span className={styles.planCostLabel}>{plan.label}</span>}
                  {plan.cost && <em>{plan.cost}</em>}
                  {plan.interval && <span>{plan.interval}</span>}
                </div>
                <p>{plan.description}</p>
              </header>
              <div className={styles.planBody}>
                <p className={styles.planTitle}>{plan.featuresTitle}</p>
                {plan.features.map(feature =>
                  <div className={styles.planFeature} key={feature._id}>
                    <span>{feature.name}</span>
                    <ToggleTip
                      title={feature.name}
                      items={feature.featureItems}
                    />
                    <ul className={styles.planFeatureList}>
                      {feature.featureItems.map(item =>
                        <li key={item._key}>{item.title}</li>
                      )}
                    </ul>
                  </div>
                )}
                <div className={styles.planLink}>
                  <Link linkUrl={plan.linkUrl} linkLabel={plan.linkLabel} />
                </div>
              </div>
            </li>
          )}
        </ul>
      </div>
    </section>
  )
}

export default PricePlans
