import React from "react"
import SectionHeader from "./sectionHeader"
import FAQ from "./faq"

import styles from "./faqs.module.scss"

const FAQs = (props) => {


  return (
    <section className={styles.faqs} data-background={props.background}>
      <div className="grid">
        <div className="gridItem xlarge-8 xlarge-offset-2">
          <SectionHeader
            title={props.sectionTitle}
            subtitle={props.sectionSubtitle}
            content={props.sectionIntro}
          />
          <div className={styles.faqsInner}>
            {props.faqs.map(faq =>
              <FAQ key={faq._id} {...faq} />
            )}
          </div>
        </div>
      </div>
    </section>
  )
}

export default FAQs
