import React from "react"
import { graphql } from "gatsby"
import { Helmet } from "react-helmet"
import SEO from "../../components/seo"
import Layout from "../../components/layout"
import Hero from "../../components/hero"
import Plans from "../../components/pricePlans"
import Callout from "../../components/callout"
import FAQs from "../../components/faqs"

export const query = graphql`
  query {
    sanityPricingPage {
      title
      _rawPlans(resolveReferences: {maxDepth: 10})
      _rawCalculator
      _rawFaqs(resolveReferences: {maxDepth: 10})
      seo {
        metaTitle
        canonical
        description
        noIndex
        openGraphImage {
          asset {
            url
          }
        }
      }
    }
  }
`

const PageTemplate = (props) => {
  const page = props.data.sanityPricingPage
  const customTitle = page.seo && page.seo.metaTitle ? page.seo.metaTitle : null
  const description = page.seo && page.seo.description ? page.seo.description : null
  const image = page.seo && page.seo.openGraphImage && page.seo.openGraphImage.asset !== null ? page.seo.openGraphImage.asset.url : null

  return (
    <Layout theme="light">
      <Helmet>
        {page.seo && page.seo.noIndex && <meta name="robots" content="noindex" />}
      </Helmet>
      <SEO
        title={page.title}
        customTitle={customTitle}
        description={description}
        image={image}
      />
      <Hero heroTitle={page.title} theme="light" graph="bar2" />
      <Plans plans={page._rawPlans} />
      <div className="grid">
        <div className="gridItem large-6 large-offset-3">
          <Callout icon={page._rawCalculator.icon} text={page._rawCalculator.text} extra />
        </div>
      </div>
      <FAQs {...page._rawFaqs} />
    </Layout>
  )
}

export default PageTemplate
