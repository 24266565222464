import React, { useState, useRef } from "react"
import RichText from "./richText"

import styles from "./faq.module.scss"

const FAQ = (props) => {
  const [setActive, setActiveState] = useState("")
  const [setHeight, setHeightState] = useState("0px")
  const content = useRef(null)

  const ToggleAccordion = () => {
    setActiveState(setActive === "" ? "active" : "")
    setHeightState(
      setActive === "active" ? "0px" : `${content.current.scrollHeight}px`
    )
  }

  return (
    <div className={styles.faq}>
      <button onClick={ToggleAccordion} data-state={setActive}>{props.question}</button>
      <div className={styles.faqAnswer} ref={content} style={{ maxHeight: `${setHeight}` }} aria-hidden={setActive === "active" ? "false" : "true"}>
        <div className={styles.faqAnswerInner}>
          <RichText blocks={props.answer} />
        </div>
      </div>
    </div>
  )
}

export default FAQ
